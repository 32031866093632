import React from "react";

function Products(){
    return(

        <span>HELLLOOOOO</span>
    );

}

export default Products;